
import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { HasProperyPart } from "@/mixins/has-property-part";

@Component({
    components: {
        InputRadio: () => import("@/components/forms/property-radio.vue"),
        ValidationProvider,
    },
})
export default class PropertyPartOther extends Mixins(HasProperyPart) {
    @Prop({ required: true }) value?: PropertyOther;

    @Prop({ default: false }) showElevator!: boolean;

    localValue: PropertyOther = {
        elevator: null,
        home_automation: null,
        compliant_elevator: null,
    };

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: PropertyOther) {
        this.localValue = newValue;
    }
}
